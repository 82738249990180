import http from "../../http-common";

const lastLogins = async () => {
  return await http
    .get(`/account/logins`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const importPhotoWhatsapp = async () => {
  return await http
    .put(`/account/photo/whatsapp`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const removePhoto = async () => {
  return await http
    .delete(`/account/photo`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const sessionGetLasts = async () => {
  return await http
    .get(`/account/sessions`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const sessionRemove = async (id) => {
  return await http
    .delete(`/account/session/${id}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};


export default {
  lastLogins,
  importPhotoWhatsapp,
  removePhoto,
  session: {
    getLated: sessionGetLasts,
    remove: sessionRemove,
  }
};
