import http from "../http-common";
import md5 from "md5";

const login = async (data) => {
  return await http
    .post("/auth", {
      phone: data.phone,
      ddi: data.ddi,
      recaptcha: data.recaptcha,
      rememberMe: data.rememberMe,
      redirect: data.redirect,
      password: data.password ? md5(data.password) : undefined,
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const readToken = async (token, recaptcha) => {
  return await http
    .get("/auth/register", {
      params: {
        token,
      },
      headers: {
        recaptcha,
      },
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const register = async (data, recaptcha) => {
  return await http
    .post("/auth/register", {
      ...data,
      password: data.password ? md5(data.password) : undefined,
      recaptcha,
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const renewToken = async (sessionToken) => {
  return await http
    .get("/auth/renew-token", {
      headers: { session: sessionToken, },
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const permissions = async () => {
  return await http
    .get("/auth/permissions")
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

// Reset password
const rpRequestToken = async ({ phone, ddi, recaptcha }) => {
  return await http
    .post("/auth/reset-password", {
      phone,
      ddi,
      recaptcha,
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const rpReadToken = async (token, recaptcha) => {
  return await http

    .get(`/auth/reset-password/${token}`, {
      headers: {
        recaptcha,
      },
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const rpUseToken = async (token, password, recaptcha) => {
  return await http
    .post(`/auth/reset-password/${token}`, {
      password: md5(password),
      recaptcha,
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const webauthnStart = async (query, recaptcha) => {
  return await http
    .get("/auth/webauthn", {
      params: query,
      headers: {
        recaptcha,
      },
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const webauthnLogin = async (data) => {
  return await http
    .post("/auth/webauthn", data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  login,
  readToken,
  register,
  renewToken,
  permissions,
  resetPassword: {
    requestToken: rpRequestToken,
    readToken: rpReadToken,
    useToken: rpUseToken,
  },
  webauthn: {
    start: webauthnStart,
    login: webauthnLogin,
  },
};
