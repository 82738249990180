import http from "@/http-common";

const getAll = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/seller`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const update = async (orgId, partyId, sellerId, data) => {
  return await http
    .put(`/admin/organization/${orgId}/party/${partyId}/seller/${sellerId}`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};


const profileUpdate = async (orgId, partyId, sellerId, data) => {
  return await http
    .put(`/admin/organization/${orgId}/party/${partyId}/seller/${sellerId}/profile`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
}

const profileDelete = async (orgId, partyId, sellerId) => {
  return await http
    .delete(`/admin/organization/${orgId}/party/${partyId}/seller/${sellerId}/profile`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
}


export default {
  getAll,
  update,
  profile: {
    update: profileUpdate,
    delete: profileDelete,
  }
};
